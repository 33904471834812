<template>
  <div class="main404">
    找不到页面,路由输入错误(404)
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  watch: {
    $route: {
      handler: function(route) {
        if (route.fullPath) {
          window.localStorage.removeItem('path');
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
.main404 {
  font-size: 2.8vh;
  color: grey;
  font-weight: 600;
  height: 10vh;
  width: 20vw;
  margin: 0 auto;
  padding-top: 10vh;
}
</style>
